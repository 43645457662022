<template>
  <el-dialog
    title="配置角色权限"
    :visible.sync="dialogVisible"
    width="620px"
    @close="dialogClose"
    @open="dialogOpen"
  >
    <el-form
      v-if="dialogVisible"
      ref="form"
      v-loading="loading"
      :model="form"
      label-width="120px"
    >
      <el-form-item
        label="资源配置"
        style="font-size:12px"
      >
        <el-tree
          ref="elTree"
          accordion
          :data="resourceList"
          node-key="id"
          show-checkbox
          style="margin-top:5px"
          :default-expand-all="false"
          :default-checked-keys="this.checkedIds"
          :highlight-current="true"
          :props="defaultProps"
        >
          <span
            slot-scope="{ node, data }"
            class="custom-tree-node"
          >
            <span>{{ node.label }}</span>
            <span /></span>

        </el-tree>
      </el-form-item>
    </el-form>
    <div
      slot="footer"
      class="dialog-footer"
    >
      <el-button @click="back">
        取 消
      </el-button>
      <el-button
        type="primary"
        @click="submitForm"
      >
        提 交
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
import { ListResource } from '@/api/system/resource/resource'
import { error, success, treeDataTranslate } from '@core/utils/utils'
import { CreateRoleResource, resourceListById } from '@/api/system/role/role'

export default {
  data() {
    return {
      dialogVisible: false,
      loading: false,
      form: {
        roleName: '',
        roleId: '',
      },
      resourceList: [],
      checkedIds: [],
      parentIdList: [],
      defaultProps: {
        children: 'children',
        label: 'name',
      },
    }
  },
  methods: {
    dialogClose() {
      this.form = {
        roleName: '',
        roleId: '',
      }
      this.parentIdList = []
      this.checkedIds = []
      this.dialogVisible = false
    },
    dialogOpen() {
      this.getResource()
    },
    back() {
      this.dialogVisible = false
    },
    submitForm() {
      const checkedKeys = this.$refs.elTree.getCheckedKeys()
      if (checkedKeys.length === 0) {
        error('请选择资源')
      } else {
        this.loading = true
        const halfCheckedKeys = this.$refs.elTree.getHalfCheckedKeys()
        const formData = {
          resourceIds: [...checkedKeys, ...halfCheckedKeys],
          roleId: this.form.roleId,
        }
        CreateRoleResource(formData)
          .then(res => {
            const resData = res.data
            this.loading = false
            if (resData.code === 0) {
              success(resData.msg)
              this.back()
            } else {
              error(resData.msg)
            }
          })
          .catch(() => {
            this.loading = false
          })
      }
      // eslint-disable-next-line consistent-return
    },
    getResource() {
      this.loading = true
      ListResource()
        .then(res => {
          const resData = res.data
          if (resData.code === 0) {
            this.resourceList = treeDataTranslate(resData.data)
            resData.data.forEach(ele => this.parentIdList.push(ele.parentId))
            this.resourceListById()
          }
          this.loading = false
        })
        .catch(() => {
          this.loading = false
        })
    },
    resourceListById() {
      resourceListById(Number(this.form.roleId))
        .then(res => {
          const resData = res.data
          if (resData.code === 0) {
            this.checkedIds = resData.data.filter(id => !this.parentIdList.includes(id))
            // console.log(this.checkedIds)
          }
        })
    },
  },
}
</script>
