import { get, post } from '@/libs/axios'

// eslint-disable-next-line import/prefer-default-export
// 角色列表
// export const GetRoleList = () => get('/system/role/GetRoleList')
// 角色下拉
export const QueryRoleOptions = () => get('/system/role/QueryRoleOptions')
// 角色用户列表
export const ListRoleUser = params => post('/sys/role/page_user', params)

// 添加修改企业角色
export const saveOrUpdateRole = params => {
  const submitUrl = params.id ? '/sys/role/update' : '/sys/role/create'
  return post(submitUrl, params)
}

// 企业角色列表
export const GetRoleList = () => get('/sys/role/list')
// 角色资源列表
export const GetRoleResourceIdList = id => get(`/sys/app/GetRoleResourceIdList?id=${id}`)
// 删除角色
export const DeleteRole = params => post('/sys/role/delete', params)
// 角色授权
export const AuthorRoleResource = params => post('/sys/app/AuthorRoleResource', params)
export const GetRoleAllUsers = params => post('/sys/role/all_users', params)

// eslint-disable-next-line import/prefer-default-export

export const resourceListById = id => get(`/sys/role/list_resource?roleId=${id}`)
// 角色用户列表

// 角色授权
export const CreateRoleResource = params => post('/sys/role/save_resource', params)
// 添加角色用户
export const AddRoleUser = params => post('/sys/role/add_user', params)
// 删除角色用户
export const DelRoleUser = params => post('/sys/role/del_user', params)
// 删除角色
